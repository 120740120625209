.sub-contact-editor {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    h3 {
        flex-basis: 100%;
        text-align: center;
        margin: 25px 0;
    }

    > * {
        flex-basis: calc(50% - 30px);
        width: 50%;
        margin: 15px;
    }

    .image-div {
        .display-image {
            position: relative;

            img {
                position: relative;
                width: 100%;
                &:hover {
                    filter: blur(8px);
                }
            }

            &:hover::after {
                content: 'Chọn ảnh mới';
                position: absolute;
                filter: none;
                top: 40%;
                left: 40%;
            }


        }

        .unknown-image {
            background-color: gray;
            color: white;
            text-align: center;
            height: 100%;
        }
    }

    .input-div {
        position: relative;

        > textarea {
            width: 100%;
            min-height: 300px;
        }

        a {
            position: absolute;
            top: 0;
            right: -30px;
        }
    }

}
