.album-layout {
    > .albums-main {
        margin-top :50px;
        margin-bottom: 50px;
    }

    > .news {
        margin-bottom: 50px;
    }

    > .contact {
        margin-bottom: 30px;
    }
}
