.sub-service {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > *{
       flex-basis: calc(50% - 24px); 
    }

    .sub-service-image {

        padding: 12px;

        img {
            width: 100%;
        }
    }

    .sub-service-content {

        padding: 12px;

        textarea {
            width: 100%;
        }
    }
}
