.account-editor {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    > * {
        height: 50px;
    }

    > *:nth-child(2n) {
        flex-basis: calc(30% - 30px);
        margin: 10px;
    }

    > *:nth-child(2n+ 1) {
        flex-basis: calc(70% - 30px);
        margin: 10px;
    }

    h3 {
        flex-basis: 100% !important;
        text-align: center;
    }

}
