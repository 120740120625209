.introduction-editor {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > *:nth-child(2n) {
        flex-basis: calc(30% - 30px);
        margin: 10px;
    }

    > *:nth-child(2n+1) {
        flex-basis: calc(70% - 30px);
        margin: 10px;
    }

    h3 {
        flex-basis: 100% !important;
        text-align: center;
    }

    .image-div {
        .display-image {
            position: relative;

            img {
                position: relative;
                width: 100%;
                &:hover {
                    filter: blur(8px);
                }
            }

            &:hover::after {
                content: 'Chọn ảnh mới';
                position: absolute;
                filter: none;
                top: 40%;
                left: 40%;
            }


        }

        .unknown-image {
            background-color: gray;
            color: white;
            text-align: center;
            height: 100%;
        }
    }

}
