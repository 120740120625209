* {
	font-family: 'Montserrat', sans-serif;
}

p {
    color: #777777;
    font-size: 20px;
    line-height: 140%;
}

h3, h4, h5 {
	color: #4F2D80;

    font-size: 40px;
}

@media(max-width: 768px) {
    h3, h4, h5 {
        font-size: 25px;
        font-weight: 400;
    }

    p {
        font-size: 16px;
    }
}

