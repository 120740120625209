div.categories {
    .category-detail {
        position: relative;

        &:before{
            content: '';
            position: absolute;
            width: 80%;
            border-bottom: 1px solid #858DBA;
            bottom: 33px;
        }

        > .container {
            position: relative;
            display: flex;
            margin-bottom: 20px;
            .image {
                height: 582px;
                flex-basis: 50%;
                position: relative;
                width: 100%;
                z-index: 10;
                display: flex;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                //&:after {
                    //content: url('../../assets/star.svg');
                    //width: 100%;
                    //height: 68px;
                    //position: absolute;
                    //bottom: 0;
                    //right: 0;
                    //transform: scaleX(-1);
                //}
                .star-img {
                    content: url('../../assets/star.svg');
                    height: 68px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform: scaleX(-1);

                }
            }
            .content {
                flex-basis: 50%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding-left: 40px;
                padding-right: 15px;
                margin-left: auto;
                h4 {
                    font-weight: 400;
                    padding-bottom: 28px;
                    color: #4F2D80;
                }

                p {
                    text-align: justify;
                    padding-bottom: 11px;
                }

                a {
                    text-align: center;
                    .btn-type-1 {
                        max-width: 154px;
                        align-self: center;
                    }

                }

            }
        }
        &:nth-child(2n) {
            .image {
                order: 2;
                .star-img {
                    left: 0;
                    transform: none;
                }
            }
            .content {
                padding-left: 0;
            }
        }

        &:nth-child(2n+1) {
            &:before {
                right: 0;
            }
        }
    }

    @media (max-width: 1420px) {
        .category-detail {
            > .container {
                .content {
                    padding-left: 80px;
                }
            }
            &:nth-child(2n+1) {
                .content {
                    padding-left: 80px;
                    padding-right: 15px;
                }
            }

        }
    }

    @media (max-width: 1279px) {
        .category-detail {
            > .container {
                .content {
                    padding-left: 40px;
                }
            }
            &:nth-child(2n+1) {
                > .container {
                    .content {
                        padding-left: 80px;
                        padding-right: 15px;
                    }
                }
            }
            &:before {
                display: none;
            }
        }
    }

    @media (max-width: 1023px) {
        .category-detail {
            > .container {
                .content {
                    padding-left: 20px;
                }
            }
            &:nth-child(2n+1) {
                > .container {
                    .content {
                        padding-left: 5px;
                        padding-right: 20px;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .category-detail {
            > .container {
                flex-wrap: wrap;

                .image {
                    height: 75vw;
                    flex-basis: 100%;

                    &:after {
                        height: 34px;
                    }

                    .star-img {
                        height: 50px;
                    }
                }	

                .content {
                    flex-basis: 100%;
                    padding-left: 0;

                    h4 {
                        text-align: center;
                        padding: 45px 0;
                    }

                    a {
                        padding: 30px 0;
                    }


                }
            }
            &:nth-child(2n) {
                .image {
                    order: 0;
                    &:after {
                        left: 0;
                        transform: none;
                    }
                }
                .content {
                    padding-left: 0;
                }
            }

        }

    }

}
