.news-summary {
    flex-basis: calc(50% - 8px);
    margin-bottom: 47px;
    display: flex;
    flex-wrap: wrap;

    .news-image {
        display:flex;
        flex-basis: 100%;
        height: 410px;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .news-details {
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        box-shadow: 0px 3px 6px rgba(168, 168, 168, 0.212);

        padding: 36px 27px 0 29px;

        .news-author {
            flex-basis: 50%;
        }

        .news-date {
            flex-basis: 100%;
            text-align: right;	
            font-size: 15px;
            color: #858DBA;

        }


        .news-title {
            flex-basis: 100%;
            font-size: 30px;
            color: #4F2D80;
            padding: 11px 0 15px 0;
            height: 70px;

        }

        .news-content {
            flex-basis: 100%;
            grid-column: 1/3;
            padding-bottom: 30px;
            //height: 180px;

            min-height: 90px;
        }

        .news-hashtag,.news-readmore {
            padding-bottom: 30px;
            font-size: 15px;
            font-style: italic;
            color: #777777;

            &:hover {
                color: #4F2D80;
            }
        }

        .news-hashtag {
            flex-basis: 50%;
        }

        .news-readmore {
            flex-basis: 50%;
            text-align: right;
        }

    }
}

@media(max-width: 1480px) {
    .news-summary {
        .news-details {
            .news-content {
                padding-top: 50px;
            }
        }
    }
}

@media(max-width: 1280px) {

    .news-summary {
        .news-details {
            .news-image {
                max-height: 410px;
            }

            .news-title {
                font-size: 25px;
            }

            .news-content {
                //height: 250px;
            }
        }
    }

}

@media(max-width: 900px) {

    .news-summary {
        .news-details {
            .news-title {
                font-size: 25px;
            }

            .news-content {
                height: 280px;
            }
        }
    }

}

@media(max-width: 767px) {
    .news-summary {
        flex-basis: 100%;

        .news-details {
            .news-title {
                font-size: 20px;
                height: unset;
            }

            .news-content {
                height: unset;
                min-height: unset;
                padding-top: unset;
            }
        }
    }

    .news-summary {
        .news-image {
            width: 100%;
            overflow: hidden;
            height: 75vw;
            position: relative;
        }
    }



}
