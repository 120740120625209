.category-layout {
	> .category-details {
		margin-top: 49px;
		margin-bottom: 80px;
	}

	> .news {
		margin-top: 80px;
		margin-bottom: 60px;
	}	

	> .contact {
		margin-bottom: 40px;
	}

    > .introduction {
        margin-bottom: 10px;
    }


}
