.navbar ul.categories {
    position: relative;
    bottom: 0;

    display: flex;
    justify-content: space-evenly;	
    width: 100%;

    list-style-type: none;
    margin: 0;
    padding-bottom: 23px;

    li {
        display: inline;
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase;
        text-align: center;
        a {
            color: #5A5A5A;
            text-decoration: none;

            &:hover {
                color: #4F2D80;
            }
        }

        &.language {
            display: none;
        }
    }
}

@media(max-width: 1700px) {
    .navbar ul.categories {
        li {
            max-width: 250px;
        }
    }
}

@media(max-width: 768px) {
    .navbar ul.categories {
        li {
            text-align: left;
            max-width: 100%;
        }
    }
}
