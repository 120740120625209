.introduction {
    position: relative;

    > .container {
        position: relative;
        z-index: 10;
        display: flex;
        padding-top: 60px;
        padding-bottom: 60px;

        .image {
            display: flex;
            flex-basis: 50%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .content {
            display: flex;
            flex-basis: 50%;
            justify-content: center;
            flex-direction: column;
            padding-left: 120px;
            margin-left: auto;
            h4 {
                color: #4F2D80;
                font-weight: 400;
                margin: 0;
                padding-bottom: 30px;
            }

            p {
                padding-bottom: 30px;
            }

            .btn-type-1 {
                align-self: center;
                color: #6B47A0;
            }
        }
    }

    .background {
        position: absolute;
        z-index:1;
        right: 0;
        background-color: #DAD4E4;
        opacity: 0.26;
        width: 75%;
        height: 100%;
    }
    .star-breakline {
        margin-top: 15px;
    }

    @media (max-width: 1279px) {
        > .container {
            .content {
                padding-left: 80px;
            }
        }
    }
    @media (max-width: 1023px) {
        > .container {
            .content {
                padding-left: 40px;
            }
        }
    }

    @media (max-width: 767px) {
        > .container {
            flex-wrap: wrap;

            .image {
                flex-basis: 100%;
            }	

            .content {
                flex-basis: 100%;
                padding-left: 0;

                h4 {
                    text-align: center;
                    padding: 30px 0;
                }


            }
        }

        .background {
            width: 100%;
        }
    }

}
