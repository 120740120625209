.sub-navbar.sub-navbar-account {
    position: relative;
    border-right: 1px solid black;
    flex-basis: calc(20% - 30px);
    padding-left: 15px;

    h3 {
        text-align: center;
        font-size: 32px;
    }

    ul {
        list-style-type: none;
        li {
            padding: 5px 0;
            a {
                font-size: 20px;
            }
        }

    }

    .add-account {
        position: absolute;
        bottom: 15px;
        right: 15px;

        background-color: green;
        color: white;
    }
}


