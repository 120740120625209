.news {
    > .container {
        display: flex;

        flex-wrap: wrap;
        justify-content: center;

        h4 {
            color: #4F2D80;
            font-weight: 400;
            text-align: center;
            padding-bottom: 109px;
        }

        .news-summaries {
            display: flex;
            flex-wrap: wrap;

            justify-content: space-between;

        }
    }
}

@media (max-width: 768px) {
    .news {
        > .container {
            h4 {
                padding-bottom: 60px;
            }
        }
    }
}
