.news-main {
    > .container {
        display: flex;

        flex-wrap: wrap;
        justify-content: center;

        h4 {
            color: #4F2D80;
            font-weight: 400;
            text-align: center;
            padding-bottom: 109px;
        }

        .news-summaries {
            display: flex;
            flex-wrap: wrap;

            justify-content: space-between;

        }

        .paging {
            color: #4F2D80;

            span {
                padding-left: 7px;
            }

            a.pageNumber {
                color: #4F2D80;

                &:after {
                    content:',';
                    padding-right:7px;
                }

                &:nth-last-child(1) {
                    &:after {
                        content: ''
                    }
                }

                &.currentPage {
                    cursor: not-allowed;
                    text-decoration: underline;
                }
            }
        }
    }
}

@media(max-width: 768px) {
    .news-main {
        > .container {
            h4 {
                padding-bottom: 45px;
            }
        }
    }
}
