.contact-details {
    .headers {
        position: relative; 

        .contactImage {
            margin-top: 45px;
            flex-basis: 100%;
            text-align: center;
            z-index: 1;
            height: 600px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .title {
        font-size: 30px;
        text-align: center;
        color: #4F2D80;

        padding-top: 90px;
        max-width: 700px;
    }

    .sub-contacts {
        .container {
            width: 80%;
            padding: 60px 0;

            .sub-contact {
                display: flex;
                flex-wrap: wrap;
                padding: 15px 0;

                > * {
                    flex-basis: calc(50% - 30px);
                    margin: 15px;
                }

                .content {
                    order: 2;
                }

                .sub-contact-image {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    height: 457px;
                    width: 674px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }
        }

    }

}

@media(max-width: 768px) {
    .contact-details {

        .title {
            padding-top: 45px;
        }

        .headers {
            .contactImage {
                height: unset;
            }
        }

        .sub-contacts {
            .container {
                padding: 30px 0;

                .sub-contact {
                    > div {
                        flex-basis: 100%;
                        margin: 15px 0;
                    }

                    .sub-contact-image {
                        height: unset;
                    }
                }
            }
        }
    }
}
