.albums-main{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    h3 {
        margin: 10px 0 109px 0;
    }

    .albums {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        > .album {
            width: calc(50% - 30px);
            box-shadow: 0px 3px 6px rgba(168, 168, 168, 0.212);
            margin: 15px;

            img {
                width: 100%;
            }

            p {
                padding: 5px 27px 15px 29px;
            }

            h4 {
                font-size: 30px;
                color: #4F2D80;
                font-weight: 400;
                padding: 15px 27px 15px 29px;
            }
        }

    }
    .paging {
        color: #4F2D80;

        span {
            padding-left: 7px;
        }

        a.pageNumber {
            color: #4F2D80;

            &:after {
                content:',';
                padding-right:7px;
            }

            &:nth-last-child(1) {
                &:after {
                    content: ''
                }
            }

            &.currentPage {
                cursor: not-allowed;
                text-decoration: underline;
            }
        }
    }
}


.swiper {
    height: 300px;
}

@media (max-width: 1024px) {
    .albums-main {
        .albums {
            .album {
                width: calc(50% - 30px);
            }
        }

    }
}

@media (max-width: 767px) {
    .albums-main {
        .albums {
            .album {
                width: 100%;
            }
        }

    }
}
