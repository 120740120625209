.unknown-image {
    background-color: gray;
    color: white;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-div {
    i.delete-icon-style {
        float: right;
    }
}

