.navbar {
    position: relative;
    padding: 15px 15px 0 15px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);

    > .container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 270px;

        .logo {
            padding-top: 35.5px;	
            width: 253px;
            height: 123px;
        }

        .left-sidebar-toggle {
            position: absolute;
            bottom: 0;
            left: 0;
            display: none;
            color: #A0A0A0;
            z-index: 999;
            margin-bottom: 5px;
        }

        .items {
            width: 100%;
        }

    }

    i.fa-bars {
        font-size: 1.25em;
    }

}

@media (max-width: 767px) {
    .navbar {
        > .container {

            .logo {
                width: 200px;

                img {
                    width: 100%;
                }
            }

            height: 210px;

            .items {
                display: none;
                &.sidebar-collapsed {
                    position: absolute;
                    display: block;
                    width: calc(100% + 25px);
                    left: -13px;
                    top: 212px;
                    z-index: 100;
                    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.161);
                    > ul {
                        display: flex;
                        flex-direction: column;
                        background-color: white;
                        padding-bottom: none;

                        > li {
                            padding: 10px 12px;
                        }
                    }
                }
            }

            .left-sidebar-toggle {
                display: block;
                margin-bottom: 10px;
            }

        }
    }
}

