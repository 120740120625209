.admin-layout {
    display: flex;
    height: 100vh;

    flex-wrap: wrap;

    a {
        color: black;

        &:hover {
            color: #6B47A0;
        }
    }

    p {
        color: black;
    }

    .navbar-admin {
        position: relative;
        flex-basis: calc(20% - 30px);
        padding: 15px 0;

        &:after {
            content: '';
            position:absolute;
            width: 100%;
            height: calc(100% - 30px);
            top: 15px;
            border-right: 2px dashed #eff2f5;
            z-index: -1;
        }

    }

    .main-editor {
        flex-basis: calc(60% - 92px);
        padding: 0 60px;
        height: 100%;
        overflow-y: scroll;
        text-align:center;

        h3 {
            height: 145px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;

        }

        .submit-btn {
            margin: 0 auto;
        }
    }

    .main-content {
        flex-basis: 70%;
    }

    .sub-navbar {
        position:relative;
        flex-basis: calc(20% - 32px);
        padding: 15px;

        height: 100%;
        overflow-y: scroll;
        > h3 {
            height: 145px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
        }

        &:after {
            content: '';
            position:absolute;
            width: 100%;
            height: calc(100% - 30px);
            top: 15px;
            border-right: 2px dashed #eff2f5;

            z-index: -1;
        }
    }
}

