.home {
	margin-top: 49px;

	> .categories {
		margin-bottom: 101px;
	}


    .introduction {
        margin-bottom: 20px;
    }

	> .star-breakline{
		margin-bottom: 20px;
	}

	> .news {
		margin-top: 79px;
		margin-bottom: 40px;
	}

	> .contact {
		margin-bottom: 48px;
	}
}
