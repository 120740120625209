.service-management {
    display: flex;
    flex-direction: column;
    align-items: center;

    .service-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 30px;
        width: 100%;

        h2 {
            grid-column: 1/3;
        }
    }
}
