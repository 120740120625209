.article-layout {

    .news {
        margin-top: 60px;
    }

    .contact {
        margin: 45px 0;
    }
}
