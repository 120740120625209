.service-layout {
    .service-details {
        padding-top: 45px;
    }
    .news{
        padding-top: 90px;
    }
    .contact {
        margin: 45px 0;
    }
}

@media (max-width: 768px) {
    .service-layout {
        .news {
            padding-top: 45px;
        }
    }
}
