.category-details {
    .header {
        position: relative;

        > .container {
            position: relative;
            display: flex;
            align-items: center;

            .image {
                position: relative;
                flex-basis: 50%;
                z-index: 10;
                height: 692px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &:after {
                    //content: '';
                    background-image: url('../../assets/star.svg');
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 67px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform: scaleX(-1);
                }

            }

            .content {
                flex-basis: 50%;
                padding-left: 120px;

                h4 {
                    font-weight: 400;
                    padding-bottom: 28px;
                    color: #4F2D80 !important;
                }
            }

        }
        &:before{
            content: '';
            position: absolute;
            width: 80%;
            border-bottom: 1px solid #858DBA;
            bottom: 33px;
            right: 0;
        }
    }

    .main-content {
        padding-top: 120px;
        > .container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            row-gap: 48px;

            .service {
                flex-basis: calc(33% - 85px);
                display: flex;
                justify-content: center;
                height: 754px;

                .service-container {
                    width: 100%;
                    box-shadow: 0px 3px 6px rgba(168, 168, 168, 0.212);
                    display:flex;
                    flex-wrap: wrap;

                    .image {
                        flex-basis: 100%;
                        position: relative;
                        display:flex;
                        height: 490px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .content {
                        flex-basis: 100%;
                        height: 264px;

                        .content-container {
                            height: 246px;
                            text-align: center;
                            position: relative;
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-template-rows: 85px 85px 43px;

                            h5 {
                                color: #848484;
                                font-size: 25px;
                                padding-top: 26px;
                                padding-bottom: 19px;
                            }

                            p {
                                padding: 5px 5px 10px 5px;
                                font-size: 16px;
                            }

                            a {
                                display:inline-block;
                            }
                        }
                    }
                }

            }
        }
    }

    @media (max-width: 1419px) {
        .header {
            > .container {
                .content {
                    padding-left: 80px;	
                }
            }
        }
    }

    @media (max-width: 1279px) {
        .main-content {
            > .container {
                .service {
                    flex-basis: calc(50% - 35px);
                }
            }
        }	
    }


    @media (max-width: 1140px) {
        .header {
            > .container {
                .image {
                    height: unset;
                }
            }
            
            &:before {
                display: none;
            }
        }
    }


    @media (max-width: 1024px) {
        .header {
            > .container {
                .content {
                    padding-left: 40px;	
                }
            }
        }
    }

    @media (max-width: 767px) {
        .header {
            > .container {
                flex-wrap: wrap;
                .image {
                    flex-basis: 100%;
                    height: 75vw;
                }

                .content {
                    flex-basis: 100%;
                    padding-left: 0;

                    h4 {
                        padding: 30px 0;
                        text-align: center;
                    }
                }
            }
        }

        .main-content {
            > .container {
                .service {
                    flex-basis: 100%;
                    height: unset;

                    .service-container {
                        .image {
                            height: 75vw;
                        }

                        .content {
                            .content-container {
                                h5 {
                                    padding: 30px 0;
                                }
                            }
                        }

                    }
                }
            }
        }	

    }


}


