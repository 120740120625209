.sub-navbar.sub-navbar-category{
    
    h3 {
        font-size: 32px;
        text-align: center;
    }

    i {
        font-size: 20px;
    }

    ul {
        list-style-type: none;
        > li {
            padding: 15px 0;

            > p {
                font-weight: 700;
                color: black;
                padding: 5px 0;
                > a {
                    text-decoration: none;
                    font-size: 20px;
                    i {
                        float: right;
                    }
                    .new-btn {
                        display: inline-block;
                        background-color: green;
                        color: white;
                        width: 15px;
                        text-align: center;
                        margin-left: 10px;
                    }
                }
            }
            .service {
                display: block;
                padding:  10px 0 10px 15px;
                font-size: 14px;
                font-weight: 400;

                a {
                    font-size: 14px;
                    font-weight: 400;
                }
                i {
                    float: right;
                }
            }


        }
    }

    .add-icon-style {
        color: green;
        padding-left: 5px;
    }
}

