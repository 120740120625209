.service-details {

    .headers {
        position: relative; 

        .container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            > div {
                flex-basis: 50%;
            }

            .content {
                text-align: center;

                h3 {
                    color: #4F2D80;
                    padding: 40px 0;
                    font-weight: 500;
                }

                p {
                    font-size: 20px;
                    font-style: italic;
                }

            }
            .serviceImage {
                text-align: center;
                height: 692px;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

        }
        .star-breakline {
            position: absolute;
            bottom: 0;
            z-index: 10;
        }

    }

    .long-description {
        padding-top: 60px;
        max-width: 1000px;
        text-align:center;
        font-size: 15px;
        font-style: italic;
    }

    .sub-title {
        text-align: center;
        color: #4F2D80;
        font-weight: 400;
        max-width: 900px;
        padding-top: 90px;
    }

    .sub-services {
        .container {
            padding: 60px 0;

            .sub-service {
                display: flex;
                padding: 15px 0;
                flex-wrap: wrap;

                > div {
                    flex-basis: calc(50% - 30px);
                    margin: 0 15px;
                }

                .content {
                    order: 2;
                }

                .sub-service-image {
                    height: 457px;
                    width: 674px;
                    padding: 0;

                    > img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

            }
        }

    }

}

@media(max-width: 768px) {
    .service-details {
        .headers {
            .container {
                > div {
                    flex-basis: 100%;
                }

                .content {
                    order: 2;

                    h3 {
                        padding: 30px 0;
                    }
                }

                .serviceImage {
                    height: unset;
                }
            }

            .star-breakline {
                display: none;
            }
        }

        .sub-title {
            padding-top: 15px;
        }

        .sub-services {
            .container {
                .sub-service {
                    > div {
                        flex-basis: 100%;
                    }

                    .content {
                        padding-top: 15px;
                    }

                    .sub-service-image {
                        height: unset;
                        width: unset;
                    }
                }
            }
        }
    }
}
