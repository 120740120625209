ul.languages {
    position: absolute;
    left: 0;
    top: 150px;
    list-style-type: none;	
    color: #BDBCBE;
    margin: 0;
    padding: 0;
    padding-left: 15px;
    li {
        display: inline;
        padding-right: 17px;
        text-transform: uppercase;
    }

    li.isSelected {
        color: #4F2D80;
    }

}

@media( max-width: 767px) {
    ul.languages {
        top : unset;
        bottom: 13px;
        right: 0;
        left: unset;

        li:nth-last-of-type(1) {
            padding-right: 0;
        }
    }
}
