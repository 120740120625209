.category-editor {
    height: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > *:nth-child(2n) {
        flex-basis: calc(20% - 30px);
        margin: 10px;
        text-align: left;
    }

    > *:nth-child(2n+1) {
        flex-basis: calc(70% - 30px);
        margin: 10px;
    }

    h3 {
        flex-basis: 100% !important;
        text-align: center;
    }

    .image-div {
        position: relative;
        width: 100%;

        .display-image {
            width: 100%;

            img {
                &:hover {
                    filter: blur(8px);
                }
            }

            &:hover::after {
                content: 'Chọn ảnh mới';
                position: absolute;
                filter: none;
                top: 40%;
                left: 40%;
            }
        }

        .unknown-image {
            background-color: gray;
            display:flex;
            justify-content: center;
            align-items: center;
            min-height: 400px;
        }
    }

}
