@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

@import '../src/sassStyles/animations.scss';
@import '../src/sassStyles/functions.scss';
@import '../src/sassStyles/global.scss';
@import '../src/sassStyles/mixins.scss';
@import '../src/sassStyles/typography.scss';
@import '../src/sassStyles/variables.scss';

.app.isLoading {
    filter: blur(8px);
}
