.sub-navbar-article {
    ul {
        list-style-type: none;

        li {
            position: relative;
            padding: 10px 0;

            a {
                p {
                    font-size: 16px;
                    width: calc(100% - 70px);
                }
            }
        }
    }

    .is-approved {
        &:after{
            position: absolute;
            top: calc(50% - 12.5px);
            right: 0;
            content: 'Đã duyệt';
            color: white;
            background-color: green;
            border-radius: 8px;
            padding: 5px;
            font-size: 12px;
        }
    }
}
