.loginForm {
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        margin:auto;
        max-width: 650px;
        display:flex;
        flex-wrap: wrap;
        justify-content: center;

        > * {
            flex-basis: 100%;
            margin: 15px 0;
            height: 40px;
        }

        .logo {
            display: flex;
            justify-content: center;
            height: unset;
        }

    }


}
