.footer {
    padding: 21px 0 18px 0;
    background-color: #4F2D80;
    > .container {
        display: flex;
        flex-wrap: no-wrap;
        justify-content: space-between;

        font-size: 15px;
        color: #A0A0A0;

        ul {
            list-style-type: none;

            li {
                display: inline;
                padding: 0 35px;
            }

            .hotline {
                padding: 0 66px;
            }

            a {
                color: #A0A0A0;
                &:hover {
                    color: white;
                }
            }
        }
    }	
}
@media (max-width: 1420px) {
    .footer {
        > .container {
            ul {
                li.hotline {
                    padding: 0 35px;
                }
            }
        }
    }
}

@media (max-width: 1310px) {
    .footer {
        padding: 10px 0;

        > .container {
            flex-wrap: wrap;

            .copyright {
                flex-basis: 100%;
                order: 2;
            }

            ul {
                flex-basis: 100%;

                li.hotline {
                    padding-left: 0;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .footer {
        > .container {

            .copyright {
                font-size: 12px;
            }

            ul {
                li {
                    padding: 0 15px;
                }
            }
        }
    }
}

@media (max-width: 530px) {
    .footer {
        > .container {
            ul {
                li {
                    padding: 5px 5px;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .footer {
        > .container {
            ul {
                display: flex;
                flex-wrap: wrap;

                justify-content: space-between;
                li.hotline {
                    flex-basis: 100%;
                }
                li:nth-child(2) {
                    padding-left:0;
                }

            }
        }
    }
}

