div.searchBox {
	position: absolute;
	top: 150px;
	right: 0;
	width: 174px;
	height: 28px;
    display: flex;
    align-items: center;
	input {
		border: none;
		height: 100%;
		width: 100%;
		background-color: #F7F7F7;
	}

	i.fa-search {
		position: absolute;
		right: 0;
        bottom: 0;
		margin-right: 5px;
        top: calc(50% - 8.2px);
		color: #9A9A9A;
	}
}

@media (max-width: 767px) {
	div.searchBox {
		top: unset;
		bottom: 10px;
        width: 150px;
        left: calc(50% - 75px);
	}
}
