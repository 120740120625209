.service-editor {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h3 {
        font-size: 20px;
        flex-basis: 100% !important;
        text-align: center;
    }

    > * {
        margin: 10px 0;
    }

    > *:nth-child(2n) {
        text-align: left;
        flex-basis: 20%;
    }
    
    > *:nth-child(2n+1) {
        flex-basis: 70%;
    }

    .image-div {
        min-height: 400px;
  
        > div {
            display: flex;
            justify-content: center;
        }
        .display-image {
            position: relative;

            img {
                position: relative;
                width: 100%;
                &:hover {
                    filter: blur(8px);
                }
            }

            &:hover::after {
                content: 'Chọn ảnh mới';
                position: absolute;
                filter: none;
                top: 40%;
                left: 40%;
            }
        }

        .unknown-image {
            background-color: gray;
            color: white;
            text-align: center;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    button {
        flex-basis: 50%;
    }


}
