html {
    border-bottom: none;
    min-height: 100%;
    height: 100%;
    body {
        height: 100%;
        padding: 0;
        margin: 0;

        ul {
            margin: 0;
            padding: 0;
        }

        a {
            text-decoration: none;

            &:visited {
                color: inherit;
            }
        }

        #root {
            white-space: pre-wrap;
            position: relative;
            min-height: 100%;
        }

        .main-page {
            border: 13px solid #4F2D80;
            border-bottom: none;
        }
        .container {
            padding: 0 15px;
            max-width: 1640px;	
            margin: 0 auto;
        }

        input {
            &:focus {
                border: 1px solid #BCA9D6 !important;
            }
        }

        .btn-type-1 {
            color: #9480B2 !important;
            background: none;
            border: 1px solid #BCA9D6 !important;
            font-size: 20px;
            padding: 9px 15px 11px 17px;
            width: 154px;
            text-align: center !important;

            &:hover {
                color: white !important;
                background-color: #6B47A0;
                border: none;
            }
        }

        .btn-type-2 {

            flex-basis: unset;
            background: none;
            border: 1px solid green !important;
            padding: 9px 11px;
            color: green;
            max-width: 160px;
            font-size: 20px;

            &:hover {
                border: none;
                background-color: green;
                color: white;
            }

        }


        .add-icon-style {
            margin-left: 5px;
            color: green;
        }

        .edit-icon-style {
            margin-left: 10px;
            color: orange;
        }

        .delete-icon-style {
            margin-left: 5px;
            color: #cd1076;
        }

        .star-breakline {
            position:relative;
            display:flex;
            height: 70px;
            width: 100%;

            &:before {
                position: absolute;
                content: '';
                width: 50%;
                height: 50%;
                color: #858DBA;
                border-bottom: 1px solid #858DBA;
            }

            &:after {
                content: url('../assets/star-black.svg'); 
                width: 50%;
                height: 68px;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 999;
            }

            &.revert {
                &:before {
                    right: 0;
                }

                &:after {
                    transform: scaleX(-1);
                    left: 0;
                }
            }

        }

        .none-display {
            display: none;
        }

        .star-img {
            content: url('../assets/star.svg');
            height: 68px;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: scaleX(-1);

        }


        .sortable-ghost {
            border: red 1px solid;
        }

        .sortable-chosen {
            border: green 1px solid;
        }

        .sortable-drag {
            border: yellow 1px solid;
        }
        
        @media (max-width: 1680px) {
            .container {
                max-width: 1420px;
            }
        }

        @media (max-width: 1420px) {
            .container {
                max-width: 1280px;
            }
        }

        @media (max-width: 1119px) {
            .container {
                max-width: 1140px;
            }
        }

        @media (max-width: 767px) {

            .container {
                max-width: 720px;
            }

            .btn-type-1 {
                max-width: 125px !important;
                font-size: 16px;
            }

            .main-page {
                border: 7px solid #4F2D80;
            }
            .star-img {
                height: 50px;
            }

        }
    }

}


