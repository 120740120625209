.contact {
    position: relative;	

    > .container {
        position: relative;
        z-index: 10;
        display: flex;
        padding-top: 60px;
        padding-bottom: 60px;

        .contact-image {
            display: flex;
            flex-basis: 50%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .content {
            flex-basis: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 85%;
            margin-left: auto;
            padding-left: 120px;

            h4 {
                font-weight: 400;
                color: #4F2D80;
                padding-bottom: 44px;
            }

            .contact-note {
                color: #A0A0A0;
                padding-bottom: 44px;
            }

            .form-input {
                display: flex;
                row-gap: 22px;
                flex-wrap: wrap;
                justify-content: center;

                input {
                    height: 23px;
                    border: none;
                    padding: 9px 10px 9px 10px;
                    flex-basis: 100%;
                    font-size: 20px;

                    &::placeholder {
                        color: #A0A0A0;
                    }
                }

                button {
                    min-width: 150px;
                }
            }
        }
    }

    .background {
        position: absolute;
        z-index:1;
        right: 0;
        background-color: #DAD4E4;
        opacity: 0.26;
        width: 75%;
        height: 100%;
    }

    @media (max-width: 1279px) {
        > .container {
            .content {
                padding-left: 80px;

                h4 {
                    font-size: 30px;
                }
            }
        }
    }
    @media (max-width: 1023px) {
        > .container {
            .content {
                padding-left: 40px;
            }
        }
    }

    @media (max-width: 767px) {
        > .container {
            flex-wrap: wrap;

            .contact-image {
                flex-basis: 100%;
            }

            .content {
                flex-basis: 100%;
                padding-left: 0;
                h4 {
                    font-size: 25px;
                    padding: 30px 0;
                }

                .form-input {
                    input {
                        font-size: 16px;
                        &::placeholder {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .background {
            width: 100%;
        }
    }


}
