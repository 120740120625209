.sub-navbar.sub-navbar-mailbox {
    flex-basis: calc(20% - 30px);
    padding: 14px;

    h3 {
        font-size: 20px;
        text-align: center;
        margin: 15px 0;
    }

    .customer {
        margin: 10px 0;
        span {
            padding: 0 5px;
            margin-left: auto;
            float: right;
        }

    }
}

.main-customer-contact {
    display: flex;
    flex-basis: 60%;
    flex-wrap: wrap;
    
    > * {
        height: 30px;

        flex-basis: 50%;
        text-align: center;
    }

    h3 {
        flex-basis: 100%;
        text-align: center;
    }

}
