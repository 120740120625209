.navbar-admin {
    display: flex;
    flex-direction: column;

    .logo {
        width: 85%;
        margin: 0 auto;

        a {
            padding: 0;
            width: 100%;
            img {
                width: 100%;
            }

        }
    }

    h5 {
        font-size: 20px;
        padding-bottom: 15px;
        padding-left: 15px;
    }

    .username-box {
        position: relative;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin:25px 0;

        > * {
            flex-basis: 50%;
            text-align: center;
            margin: 5px 0;
        }
        h5 {
            flex-basis: 100%;
            font-bold: 700;
            font-size: 16px;
            padding-left: 0px;
        }

        > .username {
            background-color: green;
            color: white;
            border-radius: 8px;

            padding: 5px;
        }

        &:after {
            content: '';
            border-bottom: 1px solid #eff2f5;
            position:absolute;
            width: 100%;
            bottom: -10px;
        }

    }

    .languages {
        display: flex;
        justify-content: center;
        position: initial;
        margin: 25px 0;
    }


    a {
        margin: 10px 0;
        font-size: 16px;
        text-decoration: none;
        padding-left: 15px;
    }
}
